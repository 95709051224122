<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex pl-1 pl-md-0">
      <dark-Toggler class="d-block" />
    </div>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex pl-1 pl-md-0">
      <a href="https://www.testudy.io/help-center" target="_blank">
          <feather-icon
          size="21"
          icon="HelpCircleIcon"
          />
          Help
      </a>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" id="nb-username">
              {{ userData.name }}
            </p>
            <span class="user-status">{{ userData.email }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/avatar.png')"
            class="badge-minimal"
            badge-variant="success"
            v-if="userData.is_premium"
            id="profile-state"
          />
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/avatar.png')"
            class="badge-minimal"
            badge-variant="secondary"
            id="profile-state"
            v-else
          />          
        </template>

        <b-dropdown-item 
        link-class="d-flex align-items-center"
        @click="goToProfile()"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>{{ userData.email }}</span>
          
        </b-dropdown-item>


        <b-dropdown-item 
        link-class="d-flex align-items-center"
        @click="goToProfile()"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
          
        </b-dropdown-item>


        <b-dropdown-divider />

          <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>

      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    goToProfile(){
      if(this.$route.name !== 'pages-profile') {
        this.$router.push({ name: 'pages-profile' })
      }
    }
  },



  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
