export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Create Test',
    route: 'addtext',
    icon: 'FilePlusIcon',
  },  
  {
    title: 'Quizzes List',
    route: 'quizzes-page',
    icon: 'ListIcon',
  },
  {
    title: 'Premium',
    route: 'pages-pricing',
    icon: 'AwardIcon',
  },
  {
    title: 'Profile',
    route: 'pages-profile',
    icon: 'UserIcon',
  },

]