<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.testudy.io"
        target="_blank"
      >Testudy.io</b-link>
      <!-- <span class="d-none d-sm-inline-block">, All rights Reserved</span> -->
      <span class="ml-1">✉️ <a :href="'mailto:info@testudy.io?subject=Need help (testudy account '+ userData.email + ')'">info@testudy.io</a></span>



    </span>

    <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue'

export default {
  components: {
    BLink, BButton
  },
  data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
      }
    }

}
</script>



